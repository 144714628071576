<template>
<div class="content-wrapper">
  <div class="pt-0 content" v-if="isList">
    <bo-page-title />
    <div class="card">
      <div class="bg-white card-header">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="form-row align-items-center">
              <div class="col-lg-5">
                <h5 class="card-title font-weight-semibold">Pasien {{ objName }}</h5>
              </div>

              <div class="col-lg-7">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-md-8 col-lg-9">
            <div class="row gx-1">
              <div class="col-md-auto">
                <div class="form-group mb-0">
                  <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                </div>
              </div>
              <div class="col-md">
                <div class="row g-1">
                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select :clearable="true" placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp" :options="mDPJP" label="text" :reduce="v=>String(v.value)"></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select :clearable="true" placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ranap_bangsal" :options="mRanapBangsal" label="text" :reduce="v=>String(v.value)"></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <b-form-group class="mb-0">
                      <v-select :clearable="true" placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status" :options="mStatus" label="text" :reduce="v=>String(v.value)"></v-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>No. Kedatangan</th>
              <th>Nama/No KTP</th>
              <th>Dokter DPJP</th>
              <th>No RM</th>
              <th>Ruangan</th>
              <th>Tanggal lahir</th>
              <th>Usia</th>
              <th>PPJP</th>
              <th>Status</th>
              <th width="120">Aksi</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td><span class="font-weight-semibold">{{ v.aranr_reg_code }}</span><br><span class="text-primary">PASIEN BARU</span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" class="font-weight-semibold border-bottom">{{ v.ap_fullname || "-" }}</a>
                    <small>{{ v.ap_code || "-" }}</small>
                  </div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>

                <td>{{ v.bu_full_name }}</td>
                <td>
                  <div class="font-weight-semibold">{{ v.ap_code || "-" }}</div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  <div class="font-weight-semibold">{{ v.ap_dob | moment("DD MMMM YYYY") }}</div>
                </td>
                <td> {{v.ap_usia_with_ket || "-"}} <span class="text-success font-weight-semibold">{{ v.ap_gol_usia }}</span></td>
                <td>{{ v.nama_ppjp || "-" }}</td>
                <td>
                  <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>

                  <b-badge variant="danger" class="mt-2" v-if="v.show_reeducation_label">Re-Edukasi</b-badge>
                </td>
                <td>
                  <a href="javascript:;" v-b-tooltip.hover.right title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-info border-info" @click="openDetail(v)" v-if="moduleRole('view')">
                    <i class="icon-file-eye"></i>
                  </a>

                  <router-link :to="{ name: $route.name, params: { pageSlug: v.aranr_id }, query: { idRegistrasi: v.aranr_id } }" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.right title="Edukasi Terintegrasi" v-if="moduleRole('edukasi_terintegrasi') && !v.show_integrated_education">
                    <i class="fa-solid icon-pencil4"></i>
                  </router-link>

                  <router-link :to="{ name: $route.name, params: { pageSlug: v.aranr_id }, query: { idRegistrasi: v.aranr_id, viewType: 'EDUKASI_HARIAN' } }" class="btn btn-icon rounded-round btn-sm alpha-warning   border-warning  text-warning-800" v-b-tooltip.hover.right title="Edukasi Harian" v-if="moduleRole('edukasi_harian')">
                    <i class="fa-solid icon-pencil4"></i>
                  </router-link>

                </td>
              </tr>
            </template>

          </tbody>
          <tbody v-if="data.data.length == 0">

            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
          <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-left"></i>
          </a>
          <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal id="detailData" size="xl" hide-footer title="Edukasi Terintegrasi Dietisien">
      <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
        <b-tab title="Edukasi Terintegrasi">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive mb-0">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th colspan="2">Uraian Penjelasan Edukasi</th>
                      <th>Penerima Penjelasan / Sasaran</th>
                      <th>Tanda Tangan</th>
                    </tr>
                  </thead>
                  <tbody v-for="(v, k) in dataModal.edukasiTerintegrasi" :key="`et-${k}`">
                    <tr class="table-info">
                      <td colspan="4">
                        <div class="d-flex align-items-center">
                          <div class="d-flex align-items-center">
                            <div class="font-weight-semibold mr-2">Form Edukasi <span class="text-capitalize">{{ (v.type||'').toLowerCase() }}</span> - {{ v.education_date | moment("LLL") }} </div>
                            <small class="text-secondary">Pelapor: <span class="text-capitalize">{{ v.type.toLowerCase() }}</span> - {{ v.nama_petugas }}</small>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" width="35%">
                        <div class="mb-2">
                          <div class="font-weight-semibold">Materi Edukasi</div>
                          <ul class="pl-3 mb-0">
                            <li v-for="(materi, kMateri) in v.materi" :key="`mat-${kMateri}`">{{ materi.label }}</li>
                          </ul>
                        </div>
                        <div class="mb-2">
                          <div class="font-weight-semibold">Tingkat Pemahaman Awal</div>
                          <span>{{ objMrPemahamanAwal[v.pemahaman_awal] }}</span>
                        </div>
                        <div class="mb-2" v-if="v.question">
                          <div class="font-weight-semibold">Pertanyaan</div>
                          <span>{{ v.question }}</span>
                        </div>
                      </td>
                      <td class="align-top" width="35%">
                        <div class="mb-2">
                          <div class="font-weight-semibold">Metode Edukasi</div>
                          <span>{{ v.metode.map(dtMetode => dtMetode.label).join(", ") }}</span>
                        </div>
                        <div class="mb-2">
                          <div class="font-weight-semibold">Sarana Edukasi</div>
                          <span>{{ v.sarana.map(dtMetode => dtMetode.label).join(", ") }}</span>
                        </div>
                        <div class="mb-2">
                          <div class="font-weight-semibold">Evaluasi</div>
                          <span>{{ objMrEvaluasi[v.evaluasi] }}</span>
                        </div>
                        <!-- Jika ada Re-edukasi -->
                        <div class="mb-2" v-if="v.reschedule_date">
                          <div class="font-weight-semibold">Tanggal Re-edukasi</div>
                          <span>{{ v.reschedule_date | moment("DD MMMM YYYY") }}</span>
                        </div>
                      </td>
                      <td class="align-top">
                        <span>{{ v.relation_patient }} - {{ v.receiver_name }}</span>
                      </td>
                      <td class="align-top text-center">
                        <a href="javascript:;" @click="viewTTD(v,'ttd_edukator')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1 d-block" title="Lihat TTD Edukator"><i class="icon-eye"></i></a>
                        
                        <a href="javascript:;" @click="viewTTD(v,'ttd_sasaran')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success mb-1 d-block" title="Lihat TTD Sasaran"><i class="icon-eye"></i></a>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="!(dataModal.edukasiTerintegrasi||[]).length">
                    <tr>
                      <td colspan="99">
                        <h5 class="text-center">
                          Belum ada data edukasi terintegrasi
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>

        
        <b-tab title="Edukasi Harian">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive mb-0">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th colspan="2">Uraian Penjelasan Edukasi</th>
                      <th>Penerima Penjelasan / Sasaran</th>
                      <th>Tanda Tangan</th>
                    </tr>
                  </thead>
                  <tbody v-for="(v, k) in dataModal.edukasiHarian" :key="`eh-${k}`">
                    <tr class="table-info">
                      <td colspan="4">
                        <div class="d-flex align-items-center">
                          <div class="d-flex align-items-center">
                            <div class="font-weight-semibold mr-2">{{ v.education_date | moment("LLL") }}</div>
                            <small class="text-secondary">Pelapor: <span class="text-capitalize">{{ (v.type||"").toLowerCase() }}</span> - {{ v.nama_petugas }}</small>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-top" colspan="2">
                        <p v-html="v.description"></p>
                      </td>
                      <td class="align-top">
                        <span>{{ v.relation_patient }} - {{ v.receiver_name }}</span>
                      </td>
                      <td class="align-top text-center">
                        <a href="javascript:;" @click="viewTTD(v,'ttd_edukator')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1 d-block" title="Lihat TTD Edukator"><i class="icon-eye"></i></a>
                        
                        <a href="javascript:;" @click="viewTTD(v,'ttd_receiver')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success mb-1 d-block" title="Lihat TTD Sasaran"><i class="icon-eye"></i></a>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="!(dataModal.edukasiHarian||[]).length">
                    <tr>
                      <td colspan="99">
                        <h5 class="text-center">
                          Belum ada data edukasi harian
                        </h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
  <span v-else>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

    <div class="content">
      <Form />
    </div>
  </span>
  
  <b-modal v-model="openTTD" :title="'TTD Digital'" size="sm" ok-only ok-title="Tutup">
    <div class="row">
      <div class="col-md-12 text-center">
        <img :src="ttdDigital" alt="">
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Form from './Form.vue'
import GlobalVue from '@/libs/Global.vue'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components: {
    PatientInfo,
    Form,
    DateRangePicker
  },
  data() {
    return {
      datePickerConfig: {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
      },
      patientData: {},
      loading: {
        patientInfo: false,
      },

      dataModal: {},
      row: {},

      mDPJP: [],
      mRanapBangsal: [],
      mStatus: [],
      mrSasaran: [],
      mrMateri: [],
      mrPemahamanAwal: [],
      mrMetode: [],
      mrSarana: [],
      mrEvaluasi: [],
      mrValidation: {},

      openTTD: false,
      ttdDigital: ''
    }
  },

  mounted() {
    if (this.isList) {
      this.doConvertDate()
      this.apiGet()

      setTimeout(() => {
        this.setSlide()
      }, 1000)
    } else {
      this.getPatientInfo()
      this.getForm()
    }
  },

  methods: {
    viewTTD(v,field){
      this.ttdDigital = v[field]
      this.openTTD = true
    },
    setSlide() {
      const responsiveTable = document.querySelector('.table-responsive')
      const responsiveTableHead = responsiveTable.querySelector('thead')
      const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
      const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
      const tableOffsetTop = responsiveTable.offsetTop + responsiveTable.offsetParent.offsetTop
      responsiveTableHead.style.position = 'relative'
      if (responsiveTableScroll) {
        responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32) + 'px'
        window.addEventListener('scroll', e => {
          responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32) + 'px'
          if (document.documentElement.scrollTop > tableOffsetTop) {
            responsiveTableHead.style.top = (document.documentElement.scrollTop - tableOffsetTop) + 64 + 'px'
          } else {
            responsiveTableHead.style.top = '0px'
          }
        })
        Array.from(responsiveTableScrollActions).forEach(action => {
          action.addEventListener('click', e => {
            const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100

            responsiveTable.scroll({
              left: scrollAmount,
              behavior: 'smooth'
            })
          })
        })
      }
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(moment().subtract(6, 'days')),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),

    openDetail(data) {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_registrasi: data.aranr_id,
            cara_masuk: data.aranres_cara_masuk,
            poliId: data.aranres_ar_id,
            ugdId: data.aranres_aur_id,
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', {
          ...data,
          ...this.dataModal,
          ...res.data
        })

        this.$set(this, 'mrPemahamanAwal', res.data.mrPemahamanAwal)
        this.$set(this, 'mrEvaluasi', res.data.mrEvaluasi)
      })

      Gen.apiRest(
        "/get/" + "RanapEdukasiTerintegrasi", {
          params: {
            type: "get-detail",
            id_registrasi: data.aranr_id,
            cara_masuk: data.aranres_cara_masuk,
            poliId: data.aranres_ar_id,
            ugdId: data.aranres_aur_id,
          }
        },
      ).then(resET => {
        this.$set(this, 'dataModal', {
          ...data,
          ...this.dataModal,
          ...resET.data
        })
      })
    },

    getForm() {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: this.$route.query.viewType === 'EDUKASI_HARIAN' ? "get-form-edukasi-harian" : "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.idRegistrasi
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    doSubmit(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: this.modulePage
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    }
  },

  computed: {
    pendidikanKesehatanGroups() {
      return _.chunk(this.dataModal.pendidikan_kesehatan, 2)
    },
    objMrPemahamanAwal() {
      return this.mrPemahamanAwal.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    },

    objMrEvaluasi() {
      return this.mrEvaluasi.reduce((acc, obj) => {
        acc[obj.value] = obj.label
        return acc
      }, {})
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      if (this.isList) {
        this.apiGet()
      } else {
        this.getPatientInfo()
        this.getForm()
      }
    }
  },
}
</script>
